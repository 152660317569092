import {
  getLanguageFromDocument,
  Language,
  type LanguageType
} from './modules/langUtil.js';
import { isInPreviewMode, isLocalDev } from './modules/previewUtil.js';
import { Site, SiteFR } from './types/template.js';
import { initCDTSPageFinalized } from './modules/onCDTSPageFinalized.js';
import ConfigModuleFactory from './config/factory.config.js';
import { getTemplate } from './modules/site.js';

window.templateFrontEnd = {
  ready: false,
  isDebug: true,
  site: getSiteForConfig(window.location.href) ?? '',
  lang: getLanguageFromDocument(),
  template: getTemplate()
};
// window.templateFront.ready = false;
// window.ewcms = {
//   isDebug: true
// };
const site = window.templateFrontEnd.site;
const lang = window.templateFrontEnd.lang;
const template = window.templateFrontEnd.template;

if (site != null) {
  const config = ConfigModuleFactory.getConfig(site);
  initCDTSPageFinalized(window.templateFrontEnd.lang, template, config);
  if (config) config.dataCDTSSetup(lang, template);
} else {
  console.error('No config file found for this site');
}

function buildSiteList() {
  const siteList: Record<string, Array<string>> = {};
  Object.keys(Site).forEach((site) => {
    siteList[Site[site]] = [Site[site]];
    siteList[Site[site]].push(Site[site].replace('-', '')); // Add site w/o dash
    Object.keys(SiteFR).forEach((siteFR) => {
      if (site === siteFR) {
        siteList[Site[site]].push(SiteFR[siteFR]);
        siteList[Site[site]].push(SiteFR[siteFR].replace('-', '')); // Add site w/o dash
      }
    });
  });
  console.debug(`Site List Build: ${JSON.stringify(siteList)}`);
  return siteList;
}

function getSiteForConfig(url: string) {
  let site: string | null = 'esdc-prv'; // Default

  if (isLocalDev(url) || isInPreviewMode(url)) {
    site = readCookie('azure.blob.storage');
  } else {
    let matchFound = false;
    const sites = buildSiteList();
    for (const name in sites) {
      sites[name].forEach((entry) => {
        if (url.includes(entry)) {
          site = name;
          matchFound = true;
        }
      });
      if (matchFound) break;
    }
  }

  console.debug('Target Site: ' + site);
  return site;
}

export function readCookie(name: string): string | null {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  let returnValue = null;

  ca.forEach((cookie) => {
    while (cookie.startsWith(' ')) {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.startsWith(nameEQ)) {
      returnValue = cookie.substring(nameEQ.length, cookie.length);
    }
  });

  return returnValue;
}

export function getUrlSearchString(lang: LanguageType) {
  const originalSearchString = window.location.search;
  if (originalSearchString) {
    if (lang === Language.ENGLISH) {
      return originalSearchString.replace(/en/g, 'fr');
    } else {
      return originalSearchString.replace(/fr/g, 'en');
    }
  }

  // TODO: what happens when this is empty?
  return '';
}
