import { type CustomWindow, type LanguageType, type TemplateType } from '../types/template.js';
import type { ConfigModule } from '../types/config.js';
import Module from './Module.js';
import type { TemplateFrontEndGlobals } from '../types/script.js';
declare let window: CustomWindow;

export default class CDTSPageFinalizedModule extends Module {
  moduleReady: boolean = false;

  async doLogic(args: unknown[]): Promise<void> {
    const lang = args[0] as LanguageType;
    const template = args[1] as TemplateType;
    const config = args[2] as ConfigModule | undefined;

    await CDTSPageFinalizedModule.onCDTSPageFinalized(lang, template, config);
    this.moduleReady = true;

    return Promise.resolve();
  }
  getModuleName(): string {
    return 'onCDTSPageFinalized';
  }

  static async onCDTSPageFinalized(
    lang: LanguageType,
    template: TemplateType,
    config: ConfigModule | undefined
  ) {
    const globals = window.templateFrontEnd as TemplateFrontEndGlobals;
    globals.ready = true;

    if (config?.hasCustomCDTSSetup() && !config.hasSplashPage() && !config.is404Page()) {
      await config?.customCDTSSetup(lang, template);
    }

    config?.appendCustomSiteScripts(lang, template);

    if (config?.hasSplashPage()) {
      setTimeout(() => {
        document.body.removeAttribute('style');
      }, 250);
    }
  }

  static initCDTSPageFinalized(
    lang: LanguageType,
    template: TemplateType,
    config: ConfigModule | undefined
  ) {
    const obj = new CDTSPageFinalizedModule();
    window.onCDTSPageFinalized = obj.run.bind(obj, lang, template, config);
  }
}
