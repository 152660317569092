import { type LanguageType } from './langUtil.js';
import { type TemplateType } from '../types/template.js';
import type { ConfigModule } from '../types/config.js';
// import WhenReady from '../WhenReady.js';

function onCDTSPageFinalized(
  lang: LanguageType,
  template: TemplateType,
  config: ConfigModule | undefined
) {
  window.templateFrontEnd.ready = true;

  if (
    config?.hasCustomCDTSSetup() &&
    !config.hasSplashPage() &&
    !config.is404Page()
  )
    config?.customCDTSSetup(lang, template);
  config?.appendCustomSiteScripts(lang, template);

  if (config?.hasSplashPage()) {
    setTimeout(() => {
      document.body.removeAttribute('style');
    }, 250);
  }
}

export function initCDTSPageFinalized(
  lang: LanguageType,
  template: TemplateType,
  config: ConfigModule | undefined
) {
  window.onCDTSPageFinalized = onCDTSPageFinalized.bind(
    {},
    lang,
    template,
    config
  );
}
