import i18next from 'i18next';
import { ConfigModuleFactory } from './config/factory.config.js';
import Module from './modules/Module.js';
import CDTSPageFinalizedModule from './modules/onCDTSPageFinalized.js';
import { getSiteForConfig, getTemplate } from './modules/site.js';
import type { CustomWindow, LanguageType } from './types/template.js';
declare let window: CustomWindow;

window.templateFrontEnd = {
  ready: false,
  isDebug: true,
  site: getSiteForConfig(window.location.href) ?? '',
  lang: i18next.language as LanguageType,
  template: getTemplate(),
  modules: Module.getModuleReadyObject()
};

const globals = window.templateFrontEnd;
const site = globals.site;
const lang = globals.lang;
const template = globals.template;

if (site != null && lang != null && template != null) {
  const config = ConfigModuleFactory.getConfig(site);
  CDTSPageFinalizedModule.initCDTSPageFinalized(lang, template, config);
  if (config) await config.dataCDTSSetup(lang, template);
} else {
  console.error('No config file found for this site');
}
